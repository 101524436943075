
import { defineComponent, PropType } from "vue-demi";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";
import { differenceTime } from "@/utils/date";
import dateFilter from '@/filters/date.filter';
import { copyLink } from "@/utils/global";
import { Channel } from "@/interfaces/channel/channel.dto";
import { useAPI } from "@/use";
import { dynamicsObject } from '@/interfaces';

export default defineComponent({
  name: 'TelegramSubscriptionSubscriber',
  props: {
    subscriber: {
      type: Object as PropType<Subscriber.Dto>,
      required: true
    }
  },
  data() {
    return {
      extendPending: false,
    };
  },
  methods: {
    dateFilter,
    async copyLink() {
      copyLink((this.subscriber.channel as Channel.Dto)?.telegram.link as string);
      this.$store.commit('createGeneralNotification', {
        message: 'Ссылка на сообщество скопирована в буфер обмена!',
        type: 'static',
      });
      await useAPI().subscriber.channelLinkMethod(this.$route.query.token as string, (this.subscriber.channel as Channel.Dto)._id);
    },
    async extend() {
      if (this.extendPending) return;

      this.extendPending = true;
      try {
        await useAPI().subscriber.extendSubscribeMethod(this.$route.query.token as string, (this.subscriber.channel as Channel.Dto)._id);
      } finally {
        this.extendPending = false;
      }

      setTimeout(() => {
        if ((window as dynamicsObject).Telegram.WebApp.close)
          (window as dynamicsObject).Telegram.WebApp.close();
      }, 200);
    },
    async removeAutoPayment() {
      await useAPI().subscriber.removeAutoPaymentMethod(this.$route.query.token as string, (this.subscriber.channel as Channel.Dto)._id);
      this.$store.commit('createGeneralNotification', {
        message: 'Автоплатёж отключен!',
        type: 'static',
      });
      this.subscriber.auto_payment = false;
    },
  },
  computed: {
    leftDays() {
      const days = Math.ceil(differenceTime(new Date(this.subscriber.subscriptionAt), new Date()));
      return days <= 0 ? 0 : days;
    }
  }
})


import { defineComponent } from "vue-demi";
import { Subscriber } from "@/interfaces/subscriber/subscriber.dto";
import { useAPI } from "@/use";
import Subscription from './subscriber/index.vue';
import { dynamicsObject } from "@/interfaces";

export default defineComponent({
  name: "TelegramSubscription",
  data() {
    return {
      list: [] as Subscriber.Dto[],
      pending: true,
    };
  },
  created() {
    this.getSubscriptionList();
  },
  mounted() {
    this.telegramScript();
  },
  methods: {
    telegramSettings() {
      if (!(window as dynamicsObject).Telegram.WebApp.isExpanded)
        (window as dynamicsObject).Telegram.WebApp.expand();
    },
    telegramScript() {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-web-app.js";
      document.body.appendChild(script);
      setTimeout(() => {
        this.telegramSettings();
      }, 100);
    },
    async getSubscriptionList() {
      const result = useAPI().subscriber.subscriptionListMethod(this.$route.query.token as string);
      result
        .then(({ data }) => {
          this.list = data.data;
        })
        .catch((err) => {
          // this.$router.push("/payment/error");
        }).finally(() => {
          this.pending = false;
        });
    },
  },
  components: {
    Subscription
  }
});

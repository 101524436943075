import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-653b6eae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscriber common flex-column fz13" }
const _hoisted_2 = {
  key: 0,
  class: "subscriber--archive fz12"
}
const _hoisted_3 = { class: "subscriber--channel" }
const _hoisted_4 = { class: "subscriber--tariff" }
const _hoisted_5 = { class: "subscriber--info flex-column" }
const _hoisted_6 = { class: "subscriptionAt" }
const _hoisted_7 = { class: "paymentAt" }
const _hoisted_8 = {
  key: 1,
  class: "btn btn--main btn--small btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.leftDays <= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Не активна"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, "Канал: " + _toDisplayString(_ctx.subscriber.channel?.name), 1),
    _createElementVNode("div", _hoisted_4, "Тариф: " + _toDisplayString(_ctx.subscriber.tariff?.name), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, "Дней осталось: " + _toDisplayString(_ctx.leftDays), 1),
      _createElementVNode("div", _hoisted_7, "Дата последней оплаты: " + _toDisplayString(_ctx.dateFilter(_ctx.subscriber.paymentAt)), 1),
      (_ctx.leftDays > 0)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn--main btn--small",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyLink && _ctx.copyLink(...args)))
          }, "Ссылка на сообщество"))
        : (_openBlock(), _createElementBlock("button", _hoisted_8, "Ссылка на сообщество")),
      _createElementVNode("button", {
        class: "btn btn--main btn--small",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.extend && _ctx.extend(...args)))
      }, "Продлить"),
      (_ctx.subscriber.auto_payment)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            class: "btn btn--main btn--small",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeAutoPayment && _ctx.removeAutoPayment(...args)))
          }, "Отменить автоплатёж"))
        : _createCommentVNode("", true)
    ])
  ]))
}
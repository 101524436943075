
import { defineComponent } from "vue-demi";
import SubscriptionMain from "@/components/telegram/subscription/index.vue";

export default defineComponent({
  name: 'SubscriptionView',
  components: {
    SubscriptionMain,
  },
});
